// REACT
import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";

// PÁGINAS
import LandingPage from './pages/LandingPage'
import LandingTrilha from './pages/LandingPage/LandingTrilha'

const StackRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* LANDING */}
                <Route path="/" element={<Outlet />} >
                    <Route path="" element={<LandingPage />}></Route>
                    <Route path="trilha/:TRILHA" element={<LandingTrilha />}></Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default StackRoutes